import React, { useCallback, useState } from 'react'
import { Button, Tooltip, Menu, MenuItem } from '@material-ui/core'
import {
  Language as LanguageIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'

function LanguageSelect() {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const {
    site: {
      siteMetadata: { languages }
    }
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            languages {
              id
              value
              label
            }
          }
        }
      }
    `
  )

  const handleClick = useCallback(event => setAnchorEl(event.currentTarget), [])
  const handleClose = useCallback(() => setAnchorEl(null), [])
  const handleOnChange = useCallback(
    language => {
      setAnchorEl(null)
      i18n.changeLanguage(language)
    },
    [i18n]
  )

  return (
    <>
      <Tooltip title={t('changeLanguage')}>
        <Button
          startIcon={<LanguageIcon />}
          endIcon={<KeyboardArrowDownIcon />}
          aria-controls='language-menu'
          aria-haspopup='true'
          onClick={handleClick}
          classes={{ text: classes.languageButtonText }}
        >
          {t(i18n.language)}
        </Button>
      </Tooltip>
      <Menu
        id='language-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map(({ id, value, label }) => {
          return (
            <MenuItem key={id} onClick={() => handleOnChange(value)}>
              {label}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  languageButtonText: {
    color: theme.palette.common.white,
    ...theme.typography.h6,
    fontWeight: 'bold'
  }
}))

export default LanguageSelect
