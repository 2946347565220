import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            name
            description
            url
            image
            fb_url
            locale
            viewport
            keywords
            color
            author
          }
        }
      }
    `
  )

  const {
    name,
    description,
    url,
    image,
    fb_url,
    locale,
    viewport,
    keywords,
    color,
    author
  } = site.siteMetadata

  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${name}`}
      meta={[
        {
          property: `og:site_name`,
          content: name
        },
        {
          name: `description`,
          content: description
        },
        {
          name: `keywords`,
          content: keywords
        },
        {
          name: `viewport`,
          content: viewport
        },
        {
          property: `og:title`,
          content: title
        },
        {
          name: 'facebook:title',
          content: name
        },
        {
          name: 'facebook:url',
          content: fb_url
        },
        {
          name: 'facebook:description',
          content: description
        },
        {
          name: 'facebook:image',
          content: image
        },
        {
          property: 'fb:app_id',
          content: '114656936585242'
        },
        {
          property: `og:url`,
          content: url
        },
        {
          property: `og:locale`,
          content: locale
        },
        {
          property: `og:image`,
          content: image
        },
        {
          name: `image`,
          content: image
        },
        {
          property: `og:name`,
          content: name
        },
        {
          property: `og:description`,
          content: description
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `theme-color`,
          content: color
        }
      ]}
    >
      <script type='application/ld+json'>
        {JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          url: url,
          headline: title,
          inLanguage: 'es',
          mainEntityOfPage: url,
          description: description,
          name: name,
          author: {
            '@type': 'Person',
            name: author
          },
          copyrightHolder: {
            '@type': 'Person',
            name: author
          },
          copyrightYear: '2020',
          creator: {
            '@type': 'Person',
            name: author
          },
          publisher: {
            '@type': 'Person',
            name: author
          },
          datePublished: '2020-10-24T10:30:00+01:00',
          dateModified: '2020-10-24T10:30:00+01:00'
        })}
      </script>
    </Helmet>
  )
}

SEO.propTypes = {
  title: PropTypes.string.isRequired
}

export default SEO
