import React from 'react'
import PropTypes from 'prop-types'
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Drawer,
  IconButton
} from '@material-ui/core'
import { Menu as MenuIcon, Close as CloseIcon } from '@material-ui/icons'
import { useStaticQuery, graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Img from 'gatsby-image'

import { Link, LanguageSelect } from 'components'
import { useSizes } from 'hooks'

function Header({ handleDrawerToggle, mobileOpen }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { mdUp, smDown } = useSizes()

  const {
    site: {
      siteMetadata: {
        appBar: { routes }
      }
    },
    file
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            appBar {
              routes {
                id
                name
                path
              }
            }
          }
        }
        file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `
  )

  return (
    <header>
      <AppBar color='primary'>
        <Toolbar>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent={mdUp ? 'space-around' : 'space-between'}
            alignItems='center'
            className={classes.container}
          >
            <Box width='100%' maxWidth={mdUp ? 60 : 50}>
              <Link to='/' underline='none'>
                <Img fluid={file.childImageSharp.fluid} alt='Capralín Logo' />
              </Link>
            </Box>

            {mdUp && (
              <>
                {routes.map(route => {
                  return (
                    <Link
                      key={route.id}
                      to={route.path}
                      color='inherit'
                      underline='none'
                    >
                      <Typography
                        variant='h6'
                        component='h1'
                        className={classes.routeText}
                      >
                        {t(route.name).toUpperCase()}
                      </Typography>
                    </Link>
                  )
                })}
                <LanguageSelect />
              </>
            )}

            {smDown && (
              <>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  edge='start'
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon className={classes.icon} fontSize='large' />
                </IconButton>

                <Drawer
                  variant='temporary'
                  anchor='top'
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper
                  }}
                  ModalProps={{
                    keepMounted: true
                  }}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                    alignItems='center'
                    p={1}
                  >
                    <Link to='/' underline='none' onClick={handleDrawerToggle}>
                      <Img
                        fluid={file.childImageSharp.fluid}
                        alt='Capralín Logo'
                        className={classes.logo}
                      />
                    </Link>

                    <IconButton
                      color='inherit'
                      aria-label='open drawer'
                      edge='start'
                      onClick={handleDrawerToggle}
                    >
                      <CloseIcon className={classes.icon} fontSize='large' />
                    </IconButton>
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    p={2}
                  >
                    {routes.map(route => {
                      return (
                        <Link
                          key={route.id}
                          to={route.path}
                          color='inherit'
                          underline='none'
                          onClick={handleDrawerToggle}
                          className={classes.routeContainerXs}
                        >
                          <Typography
                            variant='h5'
                            component='h1'
                            align='center'
                            className={classes.routeText}
                          >
                            {t(route.name).toUpperCase()}
                          </Typography>
                        </Link>
                      )
                    })}
                    <Box mt={5}>
                      <LanguageSelect />
                    </Box>
                  </Box>
                </Drawer>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </header>
  )
}

const useStyles = makeStyles(theme => {
  return {
    container: {
      flex: 1
    },
    routeText: {
      color: theme.palette.common.white,
      fontWeight: 'bold'
    },
    drawerPaper: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.primary.main
    },
    logo: {
      width: 60
    },
    icon: {
      color: theme.palette.common.white
    },
    routeContainerXs: {
      width: '100%',
      borderBottom: 'solid',
      borderBottomColor: theme.palette.common.white,
      borderBottomWidth: 2,
      paddingBottom: theme.spacing(3),
      marginTop: theme.spacing(5)
    }
  }
})

Header.propTypes = {
  handleDrawerToggle: PropTypes.func.isRequired,
  mobileOpen: PropTypes.bool.isRequired
}

export default Header
