const es = {
  translation: {
    home: 'Inicio',
    products: 'Productos',
    benefits: 'Beneficios',
    ourBenefits: 'Nuestros Beneficios',
    recipes: 'Recetas',
    aboutUs: 'Nosotros',
    language: 'Idioma',
    404: 'No encontrada',
    featuredProducts: 'Productos Destacados',
    naturalYogurt: 'Yogurt natural',
    strawberryYogurt: 'Yogurt fresa',
    pineappleYogurt: 'Yogurt piña',
    passionFruitYogurt: 'Yogurt maracuya',
    raspberryYogurt: 'Yogurt mora',
    naturalYogurtXs: 'Yogurt natural - 210ml',
    pineappleYogurtXs: 'Yogurt piña - 210ml',
    strawberryYogurtXs: 'Yogurt fresa - 210ml',
    naturalYogurtXl: 'Yogurt natural - 1000ml',
    pineappleYogurtXl: 'Yogurt piña - 1000ml',
    strawberryYogurtXl: 'Yogurt fresa - 1000ml',
    milkXl: 'Leche - 1000ml',
    ourHistory: 'Nuestra historia',
    changeLanguage: 'Cambiar lenguaje',
    en: 'Inglés',
    'en-US': 'Inglés',
    es: 'Español',
    minutes: 'Minutos',
    benefit1: 'Más fácil de digerirque la Leche de Vaca',
    benefit2: 'Naturalmente homogenizada',
    benefit3: 'Alta en vitaminas y minerales',
    benefit4: '8 gramos de proteínapor cada vaso de leche',
    benefit5: 'Deliciosa y conun sabor cremoso',
    benefit6: 'Baja en colesterol y alta en calcio',
    benefit7: 'Propiedades antiinflamatorias',
    benefit8: 'Ayuda al crecimiento y desarrollo de los niños',
    benefit9: 'Mejora el sistema inmune',
    goatMilkBenefits: 'Los Beneficios de Leche de Cabra',
    goatMilkBenefitsDescription:
      'Si bien la leche de cabra parece una opción peculiar para algunos, en realidad es la leche más popular en todo el mundo, y lo ha sido durante siglos. Las cabras producen una leche única y saludable con una serie de beneficios para su cuerpo y para el medio ambiente.',
    '100PercentDairy': 'La leche de cabra es 100% láctea',
    '100PercentDairyDescription':
      'Los lácteos se refieren a la leche producida por un animal, específicamente un mamífero como cabras, ovejas, vacas o incluso camellos y búfalos de agua. Toda la leche de mamíferos se considera láctea, pero existen diferencias en el contenido de grasa, lactosa y proteínas. Es por eso que algunas personas que son alérgicas a los productos lácteos de vaca o lactosa sensibles a los productos lácteos de vaca pueden usar los productos lácteos de cabra con éxito y disfrutar de los muchos productos lácteos de cabra disponibles.',
    easyToDigest: 'La leche de cabra es fácil de digerir',
    easyToDigestDescription:
      'Los lácteos se refieren a la leche producida por un animal, específicamente un mamífero como cabras, ovejas, vacas o incluso camellos y búfalos de agua. Toda la leche de mamíferos se considera láctea, pero existen diferencias en el contenido de grasa, lactosa y proteínas. Es por eso que algunas personas que son alérgicas a los productos lácteos de vaca o lactosa sensibles a los productos lácteos de vaca pueden usar los productos lácteos de cabra con éxito y disfrutar de los muchos productos lácteos de cabra disponibles.',
    lactoseIntolerant: '¿Intolerante a la lactosa?',
    lactoseIntolerantDescription:
      'La leche de cabra puede funcionar para usted. La leche de cabra (como toda la leche natural) contiene lactosa, pero muchas personas que reaccionan mal a la lactosa no tienen el mismo problema con la leche de cabra.',
    chocolateCookies: 'Chocolate cookies',
    oreoMilkshake: 'Batido de oreo'
  }
}

export default es
