import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@material-ui/styles'
import { I18nextProvider } from 'react-i18next'

import { themeConfig, i18nConfig } from 'config'

function Providers({ children }) {
  return (
    <ThemeProvider theme={themeConfig}>
      <I18nextProvider i18n={i18nConfig}>{children}</I18nextProvider>
    </ThemeProvider>
  )
}

Providers.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired
}

export default Providers
