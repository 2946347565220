import React from 'react'
import { Box, Link, Typography } from '@material-ui/core'
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Link as GatsbyLink } from 'components'

function Footer() {
  const classes = useStyles()

  const { footer, whiteLogo } = useStaticQuery(
    graphql`
      query {
        footer: file(relativePath: { eq: "footer.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        whiteLogo: file(relativePath: { eq: "whiteLogo.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `
  )

  return (
    <Box component='footer'>
      <Img
        fluid={footer.childImageSharp.fluid}
        alt='Footer image'
        className={classes.img}
      />

      <Box className={classes.content}>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
        >
          <Box display='flex' alignItems='center' justifyContent='center'>
            <GatsbyLink to='/' underline='none'>
              <Img
                fluid={whiteLogo.childImageSharp.fluid}
                alt='Capralín logo'
                className={classes.whiteLogoIcon}
              />
            </GatsbyLink>
          </Box>
          <Box display='flex' alignItems='center' justifyContent='space-around'>
            <Link
              target='_blank'
              rel='noreferrer'
              href={'https://www.facebook.com/capralinCR'}
            >
              <FacebookIcon fontSize='large' className={classes.socialIcon} />
            </Link>
            <Link
              target='_blank'
              rel='noreferrer'
              href={'https://www.instagram.com/capralin_cr/'}
            >
              <InstagramIcon fontSize='large' className={classes.socialIcon} />
            </Link>
          </Box>
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          px={2}
          pb={1}
        >
          <Link
            target='_blank'
            rel='noreferrer'
            href='mailto:info@capralin.com'
            underline='none'
          >
            <Typography className={classes.text}>info@capralin.com</Typography>
          </Link>

          <Typography className={classes.text}>
            {`© Capralín ${new Date().getFullYear()}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  img: {
    marginBottom: -theme.spacing(1)
  },
  content: {
    backgroundColor: theme.palette.primary.main
  },
  text: {
    color: theme.palette.common.white,
    fontWeight: 'bold'
  },
  socialIcon: {
    color: theme.palette.common.white,
    fontSize: 40,
    margin: theme.spacing(2)
  },
  whiteLogoIcon: {
    width: 80,
    height: 80
  }
}))

export default Footer
