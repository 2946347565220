import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

function ContainerComponent({ children }) {
  return <Box component='main'>{children}</Box>
}

ContainerComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired
}

export default ContainerComponent
