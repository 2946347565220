const en = {
  translation: {
    home: 'Home',
    products: 'Products',
    benefits: 'Benefits',
    ourBenefits: 'Our Benefits',
    recipes: 'Recipes',
    aboutUs: 'About us',
    language: 'Language',
    404: 'Not found',
    featuredProducts: 'Featured Products',
    naturalYogurt: 'Natural yogurt',
    strawberryYogurt: 'Strawberry yogurt',
    pineappleYogurt: 'Pineapple yogurt',
    passionFruitYogurt: 'Passion fruit yogurt',
    raspberryYogurt: 'Raspberry yogurt',
    naturalYogurtXs: 'Natural yogurt - 210ml',
    pineappleYogurtXs: 'Pineapple yogurt - 210ml',
    strawberryYogurtXs: 'Strawberry yogurt - 210ml',
    naturalYogurtXl: 'Natural yogurt - 1000ml',
    pineappleYogurtXl: 'Pineapple yogurt - 1000ml',
    strawberryYogurtXl: 'Strawberry yogurt - 1000ml',
    milkXl: 'Milk - 1000ml',
    ourHistory: 'Our history',
    changeLanguage: 'Change language',
    en: 'English',
    'en-US': 'English',
    es: 'Spanish',
    minutes: 'Minutes',
    benefit1: "Easier to digest than Cow's Milk",
    benefit2: 'Naturally homogenized',
    benefit3: 'High in vitamins and minerals',
    benefit4: '8 grams of protein for each glass of milk',
    benefit5: 'Delicious and with a creamy taste',
    benefit6: 'Low in cholesterol and high in calcium',
    benefit7: 'Anti-inflammatory properties',
    benefit8: 'Helps the growth and development of children',
    benefit9: 'Improves the immune system',
    goatMilkBenefits: 'The Benefits of Goat Milk',
    goatMilkBenefitsDescription:
      "While goat milk seems like a quirky option to some, it's actually the most popular milk around the world, and has been for centuries. Goats produce a unique and healthy milk with a number of benefits for their body and for the environment.",
    '100PercentDairy': "Goat's milk is 100% dairy",
    '100PercentDairyDescription':
      'Dairy refers to milk produced by an animal, specifically a mammal such as goats, sheep, cows, or even camels and water buffalo. All mammalian milk is considered dairy, but there are differences in fat, lactose, and protein content. That is why some people who are allergic to cow milk products or lactose sensitive to cow milk products can use goat milk products successfully and enjoy the many goat milk products available.',
    easyToDigest: "Goat's milk is easy to digest",
    easyToDigestDescription:
      'Dairy refers to milk produced by an animal, specifically a mammal such as goats, sheep, cows, or even camels and water buffalo. All mammalian milk is considered dairy, but there are differences in fat, lactose, and protein content. That is why some people who are allergic to cow milk products or lactose sensitive to cow milk products can use goat milk products successfully and enjoy the many goat milk products available.',
    lactoseIntolerant: 'Lactose intolerant?',
    lactoseIntolerantDescription:
      'Goat milk can work for you. Goat milk (like all natural milk) contains lactose, but many people who react poorly to lactose do not have the same problem with goat milk.',
    chocolateCookies: 'Chocolate cookies',
    oreoMilkshake: 'Oreo Milkshake'
  }
}

export default en
