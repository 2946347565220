import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    primary: {
      main: '#EC6CA3'
    },
    secondary: {
      main: '#3F6EB4',
      light: '#81CCE4'
    },
    silver: {
      main: '#c6c6c6'
    }
  }
})
