import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

function BaseTextField({ readOnly = false, ...props }) {
  const { t } = useTranslation()

  if (readOnly) {
    return (
      <TextField
        variant='outlined'
        margin='normal'
        fullWidth
        InputProps={{
          readOnly
        }}
        label={props.label}
        value={t(props.value)}
      />
    )
  }

  return (
    <TextField
      variant='outlined'
      margin='normal'
      fullWidth
      autoComplete='off'
      InputProps={{
        readOnly
      }}
      {...props}
    />
  )
}

BaseTextField.propTypes = {
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  props: PropTypes.object
}

export default BaseTextField
