import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { CssBaseline } from '@material-ui/core'

import Providers from './Providers'
import Header from './Header'
import Container from './Container'
import Footer from './Footer'

const Layout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(mobileOpen => !mobileOpen)
  }, [])

  return (
    <Providers>
      <CssBaseline />
      <Header handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
      <Container>{children}</Container>
      <Footer />
    </Providers>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
